footer {
  /* background: rgba(8, 8, 8, 0.85); */
  background-color: #eeeeee;
}
footer .footer-logo img {
  width: 70%;
}
footer .box {
  padding: 10px 10px;
}
footer .footer-logo p {
  color: #2f3542;
  margin: 30px 0 15px 0;
}
footer h3 {
  margin-bottom: 40px;
  font-weight: 500;
}
footer ul li {
  margin-bottom: 10px;
  list-style: none;
}
footer .link li::before {
  content: "⇒";
  color: #012fad;
  margin-right: 5px;
}
footer .footer-item {
  margin-bottom: 30px;
}
footer .footer-item img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
  border: 1px solid #c9c9c9;
}
footer .footer-item span,
footer .footer-item i {
  font-size: 12px;
  color: #2f3542;
  margin-right: 5px;
  text-transform: capitalize;
}
footer h4 {
  font-weight: 400;
  margin-top: 5px;
  font-size: 15px;
}
footer .last ul li {
  display: flex;
  margin-bottom: 30px;
  color: #2f3542;
}
footer .last i {
  font-size: 20px;
  color: #012fad;
  margin-right: 20px;
}
.footer-item.flexSB {
  justify-content: flex-start;
}

.copyright {
  text-align: center;
  padding: 20px 0;
  color: grey;
  background-color: #000;
}
.copyright p {
  font-size: 15px;
}
.copyright span {
  color: #6c5ce7;
}
.copyright i {
  color: #012fad;
}
@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 576px) {
  footer .container {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }
}
