/* .blog {
  padding: 5% 0;
} */
.blog {
  /* background: url(../../../public/img/food-bg-3.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  padding: 5% 0;
}
.blog .img {
  height: 300px;
}
.blog img {
  width: 100%;
  height: 100%;
}
.loader img {
  width: auto;
}
.blog .text {
  padding: 20px 20px;
}
.blog .blog-title h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  margin: 15px 0px;
  transition: 0.5s;
}
.blog .blog-title h1:hover {
  color: #6c5ce7;
  cursor: pointer;
}
.blog span {
  text-transform: uppercase;
  font-weight: 500;
  color: #012fad;
}
.blog span label {
  margin-left: 10px;
  color: #012fad;
  font-size: 13px;
}

.blog p {
  font-size: 15px;
  color: #747d8c;
}
.blog .btn-primary {
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}
/* Single Blog */
.singleBlog {
  background: #f8f8f8;
}
.singleBlog .container {
  background: #fff;
}
.singleBlog span {
  text-transform: uppercase;
  font-weight: 500;
  color: #012fad;
  margin: 15px 0px;
  margin-right: 15px;
}
.singleBlog span label {
  margin-left: 10px;
  color: #012fad;
  font-size: 13px;
}
.singleBlog .blog-text img {
  float: right;
  width: 40%;
  height: 400px;
  margin-left: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.singleBlog .blog-text {
  text-align: justify;
  font-size: 14px;
  color: #2f3542;
}

@media screen and (max-width: 480px) {
  .blog h1 {
    font-size: 16px;
    line-height: 20px;
  }
}
