.product-search {
  background-image: url(../../../../public/img/search2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10% 0;
  background-attachment: fixed;
  padding-top: 16%;
}
/* .product-search h2 {
  background: #012fad;
} */

.search-product-form input[type="search"] {
  width: 60%;
  padding: 8px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-right: -4px;
}
.search-product-form ::placeholder {
  font-size: 14px;
}
@media screen and (max-width: 576px) {
  .product-search {
    padding-top: 26%;
  }
}
