.contacts {
  background: #f8f8f8;
  padding: 5% 0;
}
.contacts .container {
  background: #fff;
  padding: 0;
}
.row {
  width: 50%;
}
.contacts iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.contacts .right {
  padding: 50px;
}
.contacts .items {
  border: 0;
}
.contacts h1 {
  font-size: 30px;
  font-weight: 400;
}
.contacts p {
  color: #747d8c;
}
.contacts .items {
  margin: 30px 0;
}
.contacts .box p {
  font-size: 14px;
  margin-top: 10px;
}
.contacts textarea,
.contacts input {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid #747d8c;
}
.contacts .btn-primary {
  width: auto;
  outline: none;
  border: 0;
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}
form .flexSB input:nth-child(1) {
  margin-right: 10px;
}
.contacts h3 {
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.contacts span {
  color: #1eb2a6;
  font-weight: 600;
  font-size: 15px;
  word-spacing: 5px;
}
@media screen and (max-width: 768px) {
  .contacts .container {
    flex-direction: column;
  }
  .contacts iframe {
    height: 50vh;
  }
  .contacts .row {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .contacts .right {
    padding: 20px;
  }
}
